<template>
    <div class="plan-c">
        <!-- <div class="nav">
            <div class="top-title">
                <div class="top-title-iocn"></div>
                <div class="top-title-content">{{goodsInfo.name}}</div>
            </div>
        </div> -->
        <div class="content-one">
            <div class="swipe">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <v-touch v-on:swipeleft="leftChangeImg" @panend="moveEnd" @panleft="moveImage" height="500px"
                            ref="swiper">
                            <div v-if="isShow"
                                :class="[{'swiper-slide': true}, {'active': index == bannerIndex}, {'active-next': index == bannerNext},{'active-next2': index == bannerNext2}, {'leave': index == prevIndex}]"
                                v-for="(item,index) in lenmonList" :key="index">
                                <img :src="item.img" />
                                <div class="mask">
                                    <p>{{goodsInfo.name}}</p>
                                </div>
                            </div>
                        </v-touch>
                        <div v-if="!isShow" class="one-img" v-for="(item,index) in lenmonList" :key="index">
                            <img :src="item.img" />
                            <div class="mask">
                                <p>{{goodsInfo.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-two">
            <!-- 防伪信息 -->
            <div class="goods-info">
                <div class="goods-detail">
                    <div class="detail">
                        <p class="title">商品唯一编码</p>
                        <p class="content">{{goodsInfo.num}}</p>
                    </div>
                    <div class="detail">
                        <p class="title">最新查询时间</p>
                        <p class="content">{{goodsInfo.newTime}}</p>
                    </div>
                    <div class="devide-line"></div>
                    <div class="detail last">
                        <p class="title">查询次数</p>
                        <p class="content">{{goodsInfo.queryNum}}次</p>
                        <p class="tip" v-if="goodsInfo.queryNum > 0">已多次查验,谨防假冒</p>
                    </div>
                </div>
                <div class="goods-info-bottom"></div>
            </div>
            <!-- 产品介绍 -->
            <div class="introduce product-introduce">
                <div class="introduce-title introduce-title-right">
                    <div class="title">
                        <div class="title-name">产品介绍</div>
                    </div>
                </div>
                <div class="introduce-info">
                    <div class="introduce-detail">
                        <div class="detail" v-for="(item,index) in productInfo">
                            <p class="title">{{item.name}}</p><p class="content">{{item.context}}</p>
                        </div>
                    </div>
                    <div class="right-icon"></div>
                    <div class="left-icon"></div>
                </div>
            </div>
            <!-- 检疫证明 -->
             <div class="introduce" v-if="isShowImage">
                <div class="introduce-title introduce-title-left">
                    <div class="title">
                        <div class="title-name">检疫证明</div>
                    </div>
                </div>
                <div class="map-info">
                    <div class="map">
                        <div class="imageInspection" v-for="(item, index) in goodsInfo.imageInspection" :key="'img' + index">
                            <img :src="item"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 卡通农场 -->
            <div class="introduce">
                <div class="introduce-title introduce-title-right">
                    <div class="title">
                        <div class="title-name">卡通农场</div>
                    </div>
                </div>
                <div class="cartoon">
                    <div class="cartoon-img"></div>
                    <div class="cartoon-info">
                        <p>地块农场信息</p>
                        <p>所属园区：智农农业产业园</p>
                        <p>园区地址：四川省德阳市罗江区解放路19号-2</p>
                    </div>
                </div>
            </div>
            <!-- 成长过程 -->
            <div class="introduce">
                <div class="introduce-title introduce-title-left">
                    <div class="title">
                        <div class="title-name">成长过程</div>
                    </div>
                </div>
                <div class="grow-info">
                    <div v-for="(item,index) in growthList" :key="index">
                        <div :class="[{'info-content-left': true},{'info-img-left': (index == 0)}]"
                            v-if="(index + 1) % 2 == 1">
                            <div class="info-img">
                                <img :src="item.imgUrl" />
                            </div>
                            <div :class="[{'info': true}, {'info-other': (index != 0)}]">
                                <div class="img-desc">{{ item.processName }}<img class="icon-left"
                                        src="../../assets/planC/dot-left.png" /></div>
                                <p class="time">{{ item.startTimeStr }}</p>
                            </div>
                            <div class="bg-right" v-show="index != 0"></div>
                        </div>
                        <div class="info-content-right" v-if="(index + 1) % 2 == 0">
                            <div class="bg-left"></div>
                            <div class="info">
                                <div class="img-desc">{{ item.processName }}<img class="icon-left"
                                        src="../../assets/planC/dot-right.png" /></div>
                                <p class="time">{{ item.startTimeStr }}</p>
                            </div>
                            <div class="info-img">
                                <img :src="item.imgUrl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 加工信息 -->
            <div class="introduce processing-info">
                <div class="introduce-title introduce-title-right">
                    <div class="title">
                        <div class="title-name">加工信息</div>
                    </div>
                </div>
                <div class="goods-info">
                    <div class="goods-detail">
                        <div class="detail">
                            <p class="title">批次号</p>
                            <p class="content">{{processing.machinId}}</p>
                        </div>
                        <div class="detail">
                            <p class="title">加工数量</p>
                            <p class="content">{{processing.produceNum}}</p>
                        </div>
<!--                         <div class="devide-line"></div>
                        <div class="detail last">
                            <p class="title">查询次数</p>
                            <p class="content">{{goodsInfo.queryNum}}次</p>
                        </div> -->
                    </div>
                    <div class="goods-info-bottom"></div>
                </div>
                <div class="grow-info">
                    <div v-for="(item,index) in processing.arrList" :key="'pro' + index">
                        <div :class="[{'info-content-left': true},{'info-img-left': (index == 0)}]"
                            v-if="(index + 1) % 2 == 1">
                            <div class="info-img">
                                <img :src="userSrc" />
                            </div>
                            <div :class="[{'info': true}, {'info-other': (index != 0)}]">
                                <div class="img-desc">{{ item.operate }}<img class="icon-left"
                                        src="../../assets/planC/dot-left.png" /></div>
                                <p class="time">{{ item.produceTime }}</p>
                                <p class="time">负责人:{{item.director}}</p>
                            </div>
                            <div class="bg-right" v-show="index != 0"></div>
                        </div>
                        <div class="info-content-right" v-if="(index + 1) % 2 == 0">
                            <div class="bg-left"></div>
                            <div class="info">
                                <div class="img-desc">{{ item.operate }}<img class="icon-left"
                                        src="../../assets/planC/dot-right.png" /></div>
                                <p class="time">{{ item.produceTime }}</p>
                                <p class="time">负责人:{{item.director}}</p>
                            </div>
                            <div class="info-img">
                                <img :src="userSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 包装信息 -->
            <div class="introduce">
                <div class="introduce-title introduce-title-left">
                    <div class="title">
                        <div class="title-name">包装信息</div>
                    </div>
                </div>
                <div class="introduce-info package-info">
                    <p><span class="title">批次号:</span><span class="content">{{packInfo.packId}}</span></p>
                    <p><span class="title">品牌:</span><span class="content">{{packInfo.brand}}</span></p>
                    <p><span class="title">包装规格:</span><span class="content">{{packInfo.specifications}}</span></p>
                    <p><span class="title">价格:</span><span class="content">{{packInfo.price}}</span></p>
                    <p><span class="title">购买链接:</span><span class="content">{{packInfo.buyAddr}}</span></p>
                    <p><span class="title">负责人:</span><span class="content">{{packInfo.director}}</span></p>
                    <p><span class="title">包装日期:</span><span class="content">{{packInfo.packingDate}}</span></p>
                </div>
            </div>
            <!-- 检测信息 -->
            <div class="introduce test-introduce">
                <div class="introduce-title introduce-title-right">
                    <div class="title">
                        <div class="title-name">检测信息</div>
                    </div>
                </div>
                <img v-if="testInfo.reportUrl" :src="testInfo.reportUrl"/>
                <div class="introduce-info package-info">
                    <p><span class="title">批次号:</span><span class="content">{{testInfo.testId}}</span></p>
                    <p><span class="title">检测单位:</span><span class="content">{{testInfo.testOrg}}</span></p>
                    <p><span class="title">检测项目:</span><span class="content">{{testInfo.testItem}}</span></p>
                    <p><span class="title">检测时间:</span><span class="content">{{testInfo.testTime}}</span></p>
                </div>
            </div>
            <!-- 物流地图 -->
            <div class="introduce">
                <div class="introduce-title introduce-title-left">
                    <div class="title">
                        <div class="title-name">流通信息</div>
                    </div>
                </div>
                <div class="introduce-info package-info">
                    <p><span class="title">批次号:</span><span class="content">{{logisticsInfo.logisticsId}}</span></p>
                    <p><span class="title">始发地:</span><span class="content">{{logisticsInfo.fromUnit}}</span></p>
                    <p><span class="title">流向区域:</span><span class="content">{{logisticsInfo.toAddr}}</span></p>
                    <p><span class="title">销售数量:</span><span class="content">{{logisticsInfo.saleNum}}</span></p>
                    <p><span class="title">发货时间:</span><span class="content">{{logisticsInfo.saleTime}}</span></p>
                </div>
                <div class="map-info logistics-info">
                    <div class="map">
                        <div id="map"></div>
                    </div>
                </div>
            </div> 
            <!-- 企业介绍 --> 
            <div class="introduce" v-if="companyInfo.companyName">
                <div class="introduce-title introduce-title-right">
                    <div class="title">
                        <div class="title-name">企业介绍</div>
                    </div>
                </div>
                <div class="company-info" v-if="companyInfo.companyName">
                  <van-swipe v-if="companyInfo.imgList" class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item>
                        <van-image
                            width="100%"
                            height="100%"
                            fit="fill"
                            :src="companyInfo.imgList"
                        />
                        <div class="bg-color">
                        </div>
                    </van-swipe-item>
                 </van-swipe>
                <div class="company-content">
                    <div class="company">
                    <p class="name">{{companyInfo.companyName}}</p>
                    <p id="content" :class="[{'box': showBtn}, 'content']" :content="companyInfo.describe">
                            {{companyInfo.describe}}
                        <span v-if="showBtn" class="see-more" @click="showMore">展开</span>
                    </p>
                    </div>
                 
                </div>
                <div class="company-contact">
                    <div class="contact">
                        <p class="title">企业地址</p>
                        <p class="info">{{companyInfo.address}}</p>
                    </div>
                    <div class="contact">
                        <p class="title">联系电话</p>
                        <p class="info">{{companyInfo.phoneNum}}</p>
                    </div>
                </div>
              </div>
            </div>
            <!-- 购买按钮 -->
            <div class="block-btn compatibility" v-if="purchaseUrl != 'http://'"></div>
        </div>
        <!-- <div class="buy-btn compatibility" v-if="purchaseUrl != 'http://'">
            <div class="btn"><a :href="purchaseUrl">再次购买</a></div>
        </div> -->
        <div class="buy-btn compatibility">
            <div class="btn"><a href="javascript:void(0);" @click="goBuy">再次购买</a></div>
        </div>
    </div>
</template>

<script>
import { getBaseInfo  } from '@/api/suyuan'
import { getQueryVariable  } from '@/utils/index'
 
    import 'swiper/swiper-bundle.css'
    import BMap from 'BMap';
    export default {
        name: 'planC',
        data() {
            return {
                userSrc: require('@/assets/images/user.png'),
                isShowImage: false,
                isShow: true, //判断是否显示轮播
                isNoImage: true, //判断是否显示蒙版
                bannerIndex: 0, //存储上一张图片下标
                bannerNext: 1, //存储下一张图片下标
                bannerNext2: 2, //存储再下一张图片下标
                prevIndex: null, //存储前一张图片坐标
                moveFlag: true, //是否继续滑动
                leftMove: false, //判断是左滑后停止
                timer: null, //判断用户停留时间
                flag: 0,
                lenmonList: [
                ],
                activeItemIndex: 0, //当前完全显示的图片
                goodsInfo: { //防伪信息
                    
                },
                tipShow: false, //判断防伪提示是否显示
                //产品信息
                productInfo: { },
                map: null, //存储地图
                //成长过程
                growthList: [],
                showBtn: false, //查看“更多”按钮
                imgSrc: require('../../assets/planC/bg_location.svg'),
                img: null,
                //企业介绍
                companyInfo:{
                    imgList:'',
                    companyName:'',
                    describe:'',
                    phoneNum:'',
                    address:''
                },
                batchsCode:'',//初次进入时的参数
                purchaseUrl:'',//立即购买链接
                  processing: {
                    machinId: 1,//批次号
                    produceNum: 123,//加工数量
                    arrList: [
                        {operate: '1', director: '123', produceTime: '2021-04-07 12:00:00'},
                        {operate: '1', director: '123', produceTime: '2021-04-07 12:00:00'},
                        {operate: '1', director: '123', produceTime: '2021-04-07 12:00:00'},
                        {operate: '1', director: '123', produceTime: '2021-04-07 12:00:00'},
                    ]
                },//加工信息
                packInfo: {//包装信息
                    packId: '',//批次号
                    brand: '',//品牌
                    specifications: '',//包装规格
                    price: '',//价格
                    buyAddr: '',//购买链接
                    director: '',//负责人
                    packingDate: '',//包装日期
                },
                testInfo: {//检测信息
                    testId: '',//批次号
                    testOrg: '',//检验单位
                    testItem: '',//检测项目
                    testTime: '',//检测时间
                    reportUrl: '',//检测报告
                },
                logisticsInfo: {//流通信息
                    logisticsId: '',//批次号
                    fromUnit: '',//始发地
                    toAddr: '',//流向区域
                    saleNum: '',//销售数量
                    saleTime: '',//发货时间
                }
            }
        },
        created(){
            //从url中获取参数
            this.batchsCode = getQueryVariable('batchsCode');
        },
        mounted() {
            setTimeout(() => {
                this.initMap();
                this.drawPoint(1);
            }, 500)
            
            //是否超出
            
            //获取页面基础信息
            this.getBaseInfo();
        },
        methods: {
            /**
             * 跳转到过程详情页面
             */
            toDetail(val){
                this.$router.push({name:'CplantsGrow', params:{processId: val,batchsCode:this.batchsCode}})
            },
            /**
             * 获取页面基础信息
             */
            getBaseInfo() {
                let obj = {
                    batchsCode: 'Q1S0D070N010P9D9',
                }
                getBaseInfo({batchsCode:this.batchsCode}).then( res => {
                // getBaseInfo(obj).then( res => {
                    // let data = res.data;
                    /* //商品信息
                    this.goodsInfo.name = data.goodsName;
                    this.goodsInfo.num = data.goodsUuid;
                    this.goodsInfo.newTime = data.lastQueryTime;
                    this.goodsInfo.queryNum = data.queryNum;

                    //头部轮播图
                    this.lenmonList = data.goodsImageList.map( (item) => { return  {img:item} });
                    // 产品介绍
                    this.productInfo = data.goodsIntroduceList;

                    //成长过程
                    this.growthList = data.processList;

                    //企业介绍
                    if(data.company != null){
                        this.companyInfo.companyName = data.company.companyName;
                        this.companyInfo.imgList = data.company.imageUrl;
                        this.companyInfo.describe = data.company.describe;
                        this.companyInfo.phoneNum = data.company.phoneNum;
                        this.companyInfo.address = data.company.address;
                    } */
                    let data = res.data;
                    //商品信息
                    this.goodsInfo.name = data.goodsName;
                    this.goodsInfo.num = data.goodsUuid;
                    this.goodsInfo.newTime = data.lastQueryTime;
                    this.goodsInfo.queryNum = data.queryNum;
                    this.goodsInfo.imageInspection = data.imageInspection; //头部轮播图
                    this.lenmonList = data.goodsImageList.map( (item) => { return  {img:item} });                   this.isShowImage = this.goodsInfo.imageInspection.length > 0 ? true : false;

                    //头部轮播图
                    this.goodsList = data.goodsImageList.map( (item) => { return  {img:item} });
                    // 产品介绍
                    this.productInfo = data.goodsIntroduceList;

                    //成长过程
                    this.growthList = data.processList;
                    this.packInfo = data.packInfo; //包装信息
                    this.processing = data.machinInfo;//加工信息
                    this.testInfo = data.testInfo;//检测信息
                    this.logisticsInfo = data.logisticsInfo;//流通信息
                    //企业介绍
                    if(data.company != null){
                        this.companyInfo.companyName = data.company.companyName;
                        this.companyInfo.imgList = data.company.imageUrl;
                        this.companyInfo.describe = data.company.describe;
                        this.companyInfo.phoneNum = data.company.phoneNum;
                        this.companyInfo.address = data.company.address;
                    }
                    //立即购买
                    this.purchaseUrl = data.purchaseUrl;
                    //判断有几张图片
                    this.isShow = this.lenmonList.length > 1 ? true : false;

                    //设置浏览器名称
                    document.title = this.goodsInfo.name;
                    //是否超出
                    this.ifOverflow();
                })
            },
            /**
             * 判断内容是否超出
             */
            ifOverflow(){
                setTimeout( () => {
                    var oScrollHeight = document.getElementById('content').scrollHeight;
                    var oOffsetHeight = document.getElementById('content').offsetHeight;
                    this.showBtn = oScrollHeight > oOffsetHeight ;
                },0)
            },
            /**
             * 滑动开始
             */
            moveStart() {
                // console.log(e)
            },
            /** 
             * 滑动结束
             */
            moveEnd() {
                /* this.bannerIndex++;
                if (this.bannerIndex == this.lenmonList.length) {
                    this.bannerIndex = 0;
                } */

                this.moveFlag = true;
                let dom = document.querySelector('.active');
                if (this.leftMove && dom.style.opacity < 0.25) {
                    this.leftMove = false;
                    dom.style.cssText = 'transform: translate(60px,0) rotate(0deg) opacity: 0;';
                    this.bannerIndex++;
                    if (this.bannerIndex == this.lenmonList.length) {
                        this.bannerIndex = 0;
                    }
                } else {
                    this.leftMove = false;
                    dom.style.cssText = 'transform: translate(60px,0) rotate(0deg) opacity: 1';
                }
            },
            /**滑动图片
             * 
             */
            moveImage(e) {
                // console.log(e);
                // if (this.moveFlag) {
                let dom = document.querySelector('.active');
                /* let dom1 = document.querySelector('.active-next');
                let dom2 = document.querySelector('.active-next2'); */
                if (parseInt(296 - e.center.x) > 0) {
                    let trans = (-0.7 * parseInt(296 - e.center.x));
                    let deg = -0.05 * parseInt(296 - e.center.x);
                    let opa = 1 - 0.003 * parseInt(296 - e.center.x);
                    dom.style.cssText = 'transform: translate(' + trans + 'px,0) rotate(' + deg +
                        'deg); opacity: ' + opa + '';
                    this.leftMove = true;
                    if (opa < 0.25) {
                        this.moveFlag = false;
                    }
                }
                // }

            },
            /** 
             * 向左滑动切换轮播图
             */
            leftChangeImg() {
                /*   if (!this.leftMove) {
                      this.bannerIndex++;
                  } */

            },
            /** 
             * 初始化地图
             */
            initMap() {
                this.map = new BMap.Map('map');
                let point = new BMap.Point(116.399, 39.910);
                console.log(this.map)
                // let img = this.drawPoint(1);
                this.addPolyLine();
                this.map.centerAndZoom(point, 15);
            },
            /** 绘制轨迹 
             * @param {Array} arr 经纬度集合
             */
            addPolyLine(arr) {
                arr = [new BMap.Point(116.399, 39.910),
                    new BMap.Point(116.405, 39.920),
                    new BMap.Point(116.425, 39.900)
                ];
                let polyLine = new BMap.Polyline(arr, {
                    strokeColor: '#37BDAC',
                    strokeWeight: 3,
                    strokeOpacity: 1
                });
                this.map.addOverlay(polyLine);
            },
            /** 
             * 添加标注
             */
            addMarker(img) {
                let point = new BMap.Point(116.399, 39.910);
                const icon = new BMap.Icon(img, new BMap.Size(50, 55), {
                    anchor: new BMap.Size(25, 55)
                });
                const marker = new BMap.Marker(point, {
                    icon: icon,
                });
                this.map.addOverlay(marker);
            },
            /**
             * 绘制标注点
             * @param {String} 需要绘制的文字
             */
            drawPoint(val) {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = 50;
                canvas.height = 55;
                const imageWidth = canvas.width;
                const imageHeight = canvas.height;
                ctx.fillStyle = 'transparent';
                ctx.fillRect(0, 0, imageWidth, imageHeight);
                let img = new Image();
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, 50, 55);
                    ctx.beginPath();
                    ctx.fillStyle = '#fff';
                    ctx.font = '500 18px PingFangSC-Medium, PingFang SC';
                    ctx.fillText(val, 20, 32);
                    ctx.closePath();
                    let imgUrl = '';
                    imgUrl = canvas.toDataURL("image/png");
                    this.addMarker(imgUrl)
                };
                img.src = this.imgSrc;
            },
            /** 
             * 查看更多
             */
            showMore() {
                this.showBtn = false;
                document.getElementById('content').style.maxHeight = 'none'
            },
            /** 再次购买 */
            goBuy() {
                this.$router.push({name: 'goodsInfo'});
            }
        },
        watch: {
            bannerIndex: {
                handler(val) {
                    this.prevIndex = val - 1;
                    this.bannerNext++;
                    this.bannerNext2++;
                    if (this.bannerNext == this.lenmonList.length) {
                        this.bannerNext = 0;
                    }
                    if (this.bannerNext2 == this.lenmonList.length) {
                        this.bannerNext2 = 0;
                    }

                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .plan-c {
        width: 100%;
        // height: 100%;
        position: relative;
        background-color: #4BCED0;
        .nav {
            width: 100%;
            height: 64px;

            .top-title {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                box-sizing: border-box;
                padding-top: 22px;
                padding-left: 15px;
                z-index: 99;
                background-color: #4BCED0;

                .top-title-iocn {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url('../../assets/planB/back-w.png') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }

                .top-title-content {
                    position: absolute;
                    left: calc(50% - 16px);
                    transform: translate(-50%);
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    font-family: PingFangSC-Medium, PingFang SC;
                    color: #FFF;
                    z-index: 1;
                }
            }
        }

        .content-one {
            width: 100%;
            height: 403px;

            .swipe {
                position: relative;
                height: 371px;
                padding: 16px;
                overflow: hidden;

                .swiper-container {
                    height: 100%;
                    border-radius: 10px;
                    .swiper-wrapper {
                        .one-img {
                            width: 343px;
                            height: 421px;
                            border-radius: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                            }

                            .mask {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border-radius: 10px;
                                color: #fff;
                                z-index: 1;
                                background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

                                p {
                                    position: absolute;
                                    bottom: 12px;
                                    font-weight:600;
                                    left: 16px;
                                    text-shadow:0px 2px 6px rgba(0, 0, 0, 0.34);
                                }
                            }
                        }

                        .swiper-slide {
                            opacity: 0;
                            position: absolute;
                            border-radius: 10px;
                            width: 301.5px;
                            height: 371px;
                            transform: translate(60px, 0) scale(0.8);

                            &.active-next {
                                opacity: 1 !important;
                                -webkit-transform: translate(38px, 0);
                                transform: translate(38px, 0) scale(0.9);
                                z-index: 1;
                                transition: all .2s;
                            }

                            &.active-next2 {
                                opacity: 1 !important;
                                -webkit-transform: translate(72px, 0) !important;
                                transform: translate(72px, 0) scale(0.8) !important;
                                transition: all .2s;
                                z-index: 0;
                            }

                            &.active {
                                opacity: 1;
                                z-index: 3;
                                transform: scale(1);
                                transition: all .2s;
                                transform-origin: left bottom;

                                .mask {
                                    opacity: 1;

                                }
                            }

                            .mask {
                                opacity: 0;
                                position: relative;
                                width: 100%;
                                height: 100%;
                                // margin: 0 auto;
                                margin-top: 0;
                                border-radius: 10px;
                                color: #fff;
                                font-weight: 600;
                                z-index: 1;
                                background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

                                p {
                                    position: absolute;
                                    bottom: 12px;
                                    left: 16px;
                                }
                            }

                            img {
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                margin: 0 auto;
                                margin-top: 0x;
                                border-radius: 10px;
                                vertical-align: middle;
                            }
                        }
                    }

                }
            }
        }

        .content-two {
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;

            .goods-info {
                position: relative;
                width: 343px;
                height: 102px;
                margin: 0 auto;


                .goods-detail {
                    position: absolute;
                    left: 16px;
                    z-index: 1;
                    width: 311px;
                    height: 102px;
                    padding: 16px;
                    box-sizing: border-box;
                    background: url('../../assets/planC/bg-product-info.png') no-repeat top center;
                    background-size: cover;

                    .detail {
                        display: flex;
                        width: 100%;
                        margin-bottom: 7px;
                        font-size: 12px;

                        .title {
                            width: 72px;
                            font-size: 12px;
                            color: #282B32;
                        }
                        .content {
                            // flex:1;
                            margin-left: 10px;
                            font-size: 12px;
                            color: #797C7F;
                        }
                        .tip {
                            width: 112px;
                            padding: 1px 6px;
                            font-size: 12px;
                            line-height: 14px;
                            margin-left: 10px;
                            color: #fff;
                            border-radius: 2px;
                            background-color: #FF902D;
                        }
                        
                    }
                    .last {
                        display:flex;
                        align-items:center;
                    }
                    .devide-line {
                        width: 279px;
                        height: 1px;
                        margin: 7px 0;
                        // background-color: #D9D9D9;
                        border-bottom: 1px dashed #D9D9D9;
                    }

                    
                }

                .goods-info-bottom {
                    position: absolute;
                    bottom: -8px;
                    width: 100%;
                    height: 16px;
                    border-radius: 10px;
                    // background: linear-gradient(#CEFFFE 0%, #4BD0CC 100%);
                    background: url('../../assets/planC/bg_rectangle.png') no-repeat top center;
                    background-size: cover;

                }
            }
            
            .introduce {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                margin-top: 54px;
                padding-bottom: 30px;
                .introduce-title {
                    position: absolute;
                    top: -38px;
                    width: 140px;
                    height: 55px;

                    .title {
                        .title-name {
                            height: 36px;
                            font-weight: 600;
                            line-height: 36px;
                            letter-spacing: 5px;
                            font-size: 20px;
                            color: #fff;
                            text-align: center;
                        }
                    }
                }

                .introduce-title-left {
                    left: 2px;
                    transform: rotate(-15deg);
                    background: url('../../assets/planC/bg-title-left.svg') no-repeat top center;
                    background-size: cover;
                    z-index: 1;
                }

                .introduce-title-right {
                    right: 2px;
                    transform: rotate(15deg);
                    background: url('../../assets/planC/bg-title-right.svg') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }

                /* 产品介绍 */
                .introduce-info {
                    width: 343px;
                    max-height: 246px;
                    box-sizing: border-box;
                    padding: 22px 16px 16px;
                    border-radius: 10px;
                    background-color: #fff;

                    .introduce-detail {
                        height: 100%;
                        width: 100%;
                        max-height: 210px;
                        overflow: auto;
                        .detail {
                            display: flex;
                            width: 100%;
                            margin-bottom: 8px;
                            font-size: 14px;
                            .title {
                                height: 20px;
                                line-height: 20px;
                                width: 66px;
                                color: #262626;
                            }
                            .content {
                                flex: 1;
                                color: #8C8C8C;
                                margin-left: 10px;
                                line-height: 20px;
                            }
                        }
                    }
                }

                /* 物流地图 */
                .map-info {
                    position: relative;
                    width: 343px;
                    height: 200px;
                    margin-top: 40px;
                    box-sizing: border-box;
                    padding: 4px;
                    border-radius: 10px;
                    background-color: #fff;

                    .map {
                        width: 335px;
                        height: 192px;
                        border-radius: 10px;
                        box-sizing: border-box;

                        #map {
                            width: 335px;
                            height: 192px;
                            border-radius: 10px;
                        }
                    }
                    .imageInspection {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 192px;
                        img {
                            width: 100%;
                            height: 192px;
                            border-radius: 10px;
                        }
                    }
                }
                /*  卡通农场 */
                /* 卡通农场 */
                .cartoon {
                    margin-top: 20px;
                    .cartoon-img {
                        width: 347px;
                        height: 300px;
                        background: url(~@/assets/planA/cartoon.png) no-repeat; 
                    }
                    .cartoon-info {
                        box-sizing: border-box;
                        width: 347px;
                        height: 88px;
                        padding: 16px 26px;
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                        // background: #FB9209;
                        background: #FFFFFF;
                        p {
                            font-size: 12px;
                            line-height: 16px;
                            // color: #fff;
                            color: #8C8C8C;
                        }
                        p:nth-of-type(1) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                        p:nth-of-type(2) {
                            margin-top: 7px;
                        }
                        p:nth-of-type(3) {
                            margin-top: 4px;
                        }
                    }
                }
                /* 成长过程 */
                .grow-info {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 40px;
                    box-sizing: border-box;
                    .info-content-left {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 97px;
                        margin-bottom: 4px;

                        &.info-img-left {
                            height: 80px;

                            .info-img {
                                height: 80px;
                            }
                        }

                        .info-img {
                            position: relative;
                            width: 80px;
                            height: 97px;
                            border-radius: 10px;
                            

                            img {
                                position: absolute;
                                bottom: 0;
                                width: 80px;
                                height: 80px;
                                border-radius: 10px;
                                border: 1px solid #fff;

                            }

                            .img-icon {
                                position: absolute;
                                right: 4.5px;
                                bottom: 4.5px;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background-color: #FF902D;
                                border: 1px solid #fff;

                                img {
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 16px;
                                    height: 16px;
                                    // border: 1px solid #fff;
                                }
                            }
                        }

                        .info {
                            width: 263px;
                            height: 97px;


                            .img-desc {
                                position: relative;
                                /* position: absolute;
                                top: 10px;
                                left: 0; */
                                display: inline-block;
                                height: 32px;
                                margin-top: 10px;
                                padding: 0 15px;
                                line-height: 32px;
                                font-size: 16px;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                color: #262626;
                                background-color: #fff;

                                .icon-left {
                                    position: absolute;
                                    top: -10px;
                                    right: -2px;
                                    width: 11px;
                                    height: 20px;
                                }
                            }

                            .time {
                                margin-top: 6px;
                                margin-left: 15px;
                                font-size: 14px;
                                color: #FFFFFF;
                                
                            }

                            &.info-other {
                                padding-top: 17px;
                            }
                        }

                        .bg-right {
                            position: absolute;
                            right: 0;
                            width: 177px;
                            height: 92px;
                            background: url(../../assets/planC/bg-grow-right.png) no-repeat top center;
                            background-size: cover;
                        }
                    }

                    .info-content-right {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 97px;
                        padding-bottom: 4px;
                        .bg-left {
                            position: absolute;
                            left: 0;
                            width: 177px;
                            height: 92px;
                            background: url(../../assets/planC/bg-grow-left.png) no-repeat top center;
                            background-size: cover;
                        }

                        .info {
                            width: 263px;
                            padding-top: 17px;
                            text-align: right;

                            .img-desc {
                                position: relative;
                                display: inline-block;
                                height: 32px;
                                margin-top: 10px;
                                padding: 0 15px;
                                line-height: 32px;
                                font-size: 16px;
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                                color: #262626;
                                background-color: #fff;

                                .icon-left {
                                    position: absolute;
                                    top: -10px;
                                    left: -2px;
                                    width: 11px;
                                    height: 20px;
                                }
                            }

                            .time {
                                margin-top: 6px;
                                margin-left: 15px;
                                font-size: 14px;
                                color: #FFFFFF;
                                padding-right:15px;
                            }

                        }

                        .info-img {
                            position: relative;
                            width: 80px;
                            height: 97px;
                            border-radius: 10px;

                            img {
                                position: absolute;
                                bottom: 0;
                                width: 80px;
                                height: 80px;
                                border-radius: 10px;
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
                /* 加工信息 */
                &.processing-info {
                    .goods-info {
                        width: 100%;
                        height: 80px;
                        .goods-detail {
                            height: 100%;
                        }
                    }
                    
                }
                /* 包装信息 */
                .package-info {
                    box-sizing: border-box;
                    padding: 16px 16px;
                    p {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        span {
                            display: inline-block;
                            font-size: 14px;
                            color: #8c8c8c;
                        }
                        .title {
                            min-width: 60px;
                            text-align: right;
                        }
                        .content {
                            margin-left: 10px;
                        }
                    }
                }
                /* 检测信息 */
                &.test-introduce {
                    img {
                        width: 100%;
                        height: 160px;
                        margin-top: 10px;
                        border-radius: 10px;
                    }
                }
                /* 流通信息 */
                .logistics-info {
                    margin-top: 10px;
                }
                /* 企业介绍 */
                .company-info {
                    width: 343px;
                    background-color: #fff;
                    border-radius:10px;
                    .my-swipe {
                        width: 100%;
                        height: 160px;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;

                        img {
                            width: 100%;
                            height: 160px;
                            border-top-right-radius: 10px;
                            border-top-left-radius: 10px;
                        }
                    }

                    .company-content {
                        width: 100%;
                        padding: 16px;
                        padding-bottom:0;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding-left:16px;
                        padding-right: 16px;
                        background-color: #fff;
                        .company {
                            width:100%;
                            height:auto;
                            padding-bottom:12px;
                            border-bottom: 1px solid rgba(240, 240, 240, .6);
                            .name {
                                width: 100%;
                                font-size: 16px;
                                color: #262626;
                                line-height: 22.5px;
                                font-weight: 600;
                            }

                            .content {
                                position: relative;
                                width: 100%;
                                margin-top: 8px;
                                font-size: 14px;
                                max-height: 84px;
                                color: #8C8C8C;
                                text-align: justify;
                                overflow: hidden;
                            }

                            .box::before,
                            .box::after {
                                content: attr(content);
                                overflow: hidden;
                                position: absolute;
                                width: 100%;
                                left: 0;
                                top: 0;
                                color: rgba(140, 140, 140, 1);
                                line-height: 21px;
                            }

                            .box::before {
                                max-height: 61px;
                                z-index: 2;
                                background: #fff;
                            }

                            .box::after {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                                -webkit-line-clamp: 4;
                                text-indent: -6em;
                                padding-right: 2em;
                            }

                            .box {
                                color: #fff;

                                .see-more {
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    z-index: 3;
                                    color: #000;
                                    line-height: 21px;
                                }
                            }
                        }
                        
                    }

                    .company-contact {
                        width: 100%;
                        padding: 12px 16px 8px 16px;
                        box-sizing: border-box;
                        .contact {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-bottom: 8px;

                            .title {
                                min-width: 56px;
                                font-size: 14px;
                                color: rgba(38, 38, 38, 1);
                            }

                            .info {
                                flex: 1;
                                margin-left: 20px;
                                font-size: 14px;
                                color: rgba(140, 140, 140, 1);
                            }
                        }
                    }
                }
                
            }
            .product-introduce {
                margin-top: 84px;
            }
            .block-btn {
                width: 100%;
                height: 60px;
                margin-top: 25px;
            }
        }
        .buy-btn {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0px;
            width: 100%;
            height: 60px;
            background: rgba(250,250,250,0.6);
	        backdrop-filter: blur(10px);
            font-weight:600;
            z-index: 999;
            .btn {
                width: 343px;
                height: 44px;
                margin: 5px auto;
                font-size: 20px;
                line-height: 44px;
                border-radius: 22px;
                text-align: center;
                color: #fff;
                background: linear-gradient(135deg, #4BCED0 0%, #7CD6A6 100%);
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color:#fff;
                    border-radius:22px;
                }
            }
        }
    }
</style>
<style scoped>
    .leave {
        /*   animation: animate .3s linear;
        z-index: 3; */
    }

    @keyframes animate {
        0% {
            opacity: 1;
            transform: translate(0, 0) rotate(0deg);
            transform-origin: left bottom;
        }

        100% {
            transform: translate(-200px, 0) rotate(-15deg);
            opacity: 0;
            transform-origin: left bottom;
        }
    }
</style>